<template>
  <div class="digital-twin-page">
    <Nav />
    <PageHero :heroSrc="heroSrc" :title="title" :sub="sub" />
    <SubPageContent :sections="sections" :pageName="'xr'" />

    <!-- <Menu /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";

import PageHero from "@/components/PageHero.vue";
import SubPageContent from "@/components/SubPageContent.vue";
import Menu from "@/components/Menu.vue";

export default {
  name: "DigitalTwin",
  components: { PageHero, Menu, SubPageContent,Nav },
  data: () => {
    return {
      heroSrc: "img/xr-bk.jpg",
      title: "XR: A WHOLE NEW UNIVERSE",
      sections: [
        {
          p:
            "The future is now. Using XR (extended reality: VR/AR/MR) devices, we can create the ultimate simulators in a variety of uses and fields. Through XR tech we can simulate anything with unlimited options and abilities.",
        },
        {
          p:
            "XR tech is the perfect way to teach, inspire, explore, stimulate and excite. XR tech is suitable for personal use and independent study, but also using multiple headsets allows sharing information and communicate between headsets so all users can take part, observe, influence, learn and lecture.",
        },
        {
          p:
            "XR tech is an upgrade for any use case and suitable for every domain. Use XR to teach and learn, fix and assemble, operate and investigate, train and maintain. Display and process deeper and further.",
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
</style>